import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
//COMPONENTS
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LoadingOverlay from "react-loading-overlay";
import { observer } from "mobx-react-lite";
// PAGES
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import useStore from "./hooks/useStores";
import { useEffect } from "react";
import PublicRoute from "./components/PrivateRoute/PublicRoute";
import authService from "./services/authService";
import PaymentEnd from "./components/PaymentEnd";

const App = observer(() => {
  const { loading, auth } = useStore();

  useEffect(() => {
    const query = new URLSearchParams(window?.location?.search);

    const success = query?.get("success");

    if (success) {
      window.location.href = "/payment-end/callback";
    }

    if (auth.accessToken) {
      authService.setAuthorizationToken(auth.accessToken);
      auth.setLogged(true);
    }
  }, [auth]);

  return (
    <LoadingOverlay
      active={loading.overlayLoading}
      spinner
      text={"Yükleniyor..."}
    >
      <Router>
        <Switch>
          <PublicRoute path="/login" component={Login} />
          <PrivateRoute path="/payment-end/callback" component={PaymentEnd} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Redirect to={"/login"} />
        </Switch>
      </Router>
    </LoadingOverlay>
  );
});

export default App;

import jwtDecode from "jwt-decode";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useStore from "../hooks/useStores";
import CrystalLogoSmall from "../images/crslogo.png";
import instance from "../lib/client";
import authService from "../services/authService";
import notif from "../utils/notify";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import pattern from "../images/waves-white.svg";

const Login = observer(() => {
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const { loading, auth } = useStore();
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit();
  }

  const onSubmit = async () => {
    loading.setOverlayLoading(true);
    instance
      .post("user/login", user)
      .then(({ data }) => {
        console.log(data.token);

        auth.setAccessToken(data.token);
        authService.setAuthorizationToken(data.token);
        auth.setUser(jwtDecode(data.token));
        auth.setLogged(true);
        if (jwtDecode(data.token).type === "admin") {
          history.push("/dashboard/vomsis");
        } else {
          history.push("/dashboard");
        }

        loading.setOverlayLoading(false);
        notif.success("Giriş Başarılı");
      })
      .catch((err) => {
        loading.setOverlayLoading(false);
        notif.error("Kullanıcı adı veya şifre hatalı!");
      });
  };

  return (
    <>
      <div
        className="gr:lr-6"
        style={{ width: "100vw", display: "flex", justifyContent: "center" }}
      >
        <ToastContainer />
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 gr:ulr-6">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-28 w-auto gr:rd-6"
              src={CrystalLogoSmall}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Hesabınıza Giriş Yapınız
            </h2>
          </div>

          <form onSubmit={handleSubmit}>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Kullanıcı Adı
                  </label>
                  <div className="mt-1">
                    <input
                      id="username"
                      name="username"
                      type="username"
                      autoComplete="username"
                      value={user.username}
                      onChange={(e) =>
                        setUser({ ...user, username: e.target.value })
                      }
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Şifre
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={user.password}
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Giriş Yap
                  </button>
                </div>
              </div>
            </div>
          </div>
          </form>
        </div>
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 gr:alr-6">
          <div
            className="md:flex xs:hidden"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50vw",
              height: "calc(100% - 2rem)",
              position: "relative",
              textAlign: "center",
              margin: "16px",
              background: "linear-gradient(310deg, #141727, #3a416f)",
              borderRadius: ".75rem",
              overflow: "hidden",
            }}
          >
            <img
              src={pattern}
              alt="pattern-lines"
              style={{
                position: "absolute",
                opacity: ".5",
                background: "transparent",
              }}
            />
            <img
              src={CrystalLogoSmall}
              alt="crslog"
              style={{
                width: "100%",
                maxWidth: "31.25rem",
                background: "transparent",
              }}
            />
            <h4
              style={{
                marginTop: "48px",
                marginBottom: "8px",
                fontSize: "1.5rem",
                lineHeight: "1.375",
                color: "white",
                fontWeight: "700",
              }}
            >
              HAYATI KOLAYLAŞTIRIR
            </h4>
          </div>
        </div>
      </div>
    </>
  );
});

export default Login;

import { api } from "../lib/client";

const downloadExcelFile = async (url, fileName, token, setIsDownloading) => {
  //   instance
  //     .get(url, {
  //       headers: {
  //         "Content-Type": "xlsx",
  //         Authorization: `Bearer ${token}`,
  //         responseType: "blob",
  //       },
  //     })
  //     .then((response) => response.data.blob())
  //     .then((blob) => {
  //       // Create blob link to download
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", fileName + `.pdf`);
  //       // Append to html link element page
  //       document.body.appendChild(link);
  //       // Start download
  //       link.click();
  //       // Clean up and remove the link
  //       link.parentNode.removeChild(link);
  //       setIsDownloading(false);
  //     });

  fetch(api + url, {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "xlsx" },
  })
    .then((response) => response.blob())
    .then((blob) => {
      console.log(blob);
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + `.xlsx`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setIsDownloading(false);
    });
};

export default downloadExcelFile;

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdOutlineClose } from "react-icons/md";
import { observer } from "mobx-react-lite";
import useStore from "../hooks/useStores";
import numberToMoney from "../utils/numberToMoney";
import { useRouteMatch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import instance from "../lib/client";
import notif from "../utils/notify";

const ShopCart = observer(() => {
  const location = useLocation();
  const { cart } = useStore();

  const getTotalPrice = () => {
    let totalPrice = 0;
    let groupedByCurrency = {};
    cart.cart.forEach((item) => {
      groupedByCurrency[item.cinsi || "TL"] =
        groupedByCurrency[item.cinsi || "TL"] || 0;

      groupedByCurrency[item.cinsi || "TL"] += item.fiyat * item.adet;
    });

    return groupedByCurrency;
  };

  const sendProducts = async () => {
    if (cart.cart.length > 0) {
      const { data } = await instance.post("orders", { products: cart.cart });
      console.log(data);
      console.log(cart.cart);
      notif.success("Siparişiniz oluşturulmuştur!");
      cart.deleteCart();
    } else {
      notif.error("Sepete ürün eklemelisiniz!");
    }
  };

  if (location.pathname.includes("/product"))
    return (
      <>
        <Transition.Root show={cart.isCartOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 overflow-hidden"
            onClose={() => cart.setIsCartOpen(false)}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen 2xl:hidden xl:flex lg:flex md:flex xs:flex max-w-md">
                    <div className="h-full w-screen flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                      <div
                        className="px-4"
                        style={{
                          marginTop: 40,
                        }}
                      >
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Sepet
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => cart.setIsCartOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <MdOutlineClose
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 relative flex-1 px-4 sm:px-6">
                        {/* Replace with your content */}
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <div
                            className="h-full border-0 gap-y-2 flex flex-col border-gray-200"
                            aria-hidden="true"
                          >
                            {cart.cart.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <div className="flex-1 min-w-0 pr-4">
                                    <span className="focus:outline-none">
                                      <p className="text-sm font-medium text-gray-900">
                                        {item.stokadi}
                                      </p>
                                      <div className="flex flex-row justify-between">
                                        <p className="text-sm text-gray-500 truncate">
                                          Adet: {item.adet}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate">
                                          Fiyat: {numberToMoney(item.fiyat)}{" "}
                                          {item.cinsi || "₺"}
                                        </p>
                                      </div>
                                    </span>
                                  </div>
                                  <MdOutlineClose
                                    className="absolute right-2 top-2 cursor-pointer"
                                    onClick={() => cart.removeItemFromCart(i)}
                                  />
                                </div>
                              );
                            })}
                            <div className="mt-auto text-md flex-col flex">
                              <span className="w-full bg-gray-500 text-white font-bold py-2 flex items-center justify-center rounded-md mb-2">
                                {cart.cart.length > 0 ? (
                                  <div className="flex flex-col">
                                    {Object.entries(getTotalPrice()).map(
                                      ([key, value]) => {
                                        return (
                                          <span>
                                            Toplam Tutar {key} :{" "}
                                            {numberToMoney(value)}
                                          </span>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  0
                                )}
                              </span>
                              <span className="w-full bg-green-400 text-white font-bold py-2 flex items-center justify-center rounded-md">
                                Siparişi Onayla
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* /End replace */}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div
          className="h-screen xs:hidden md:hidden lg:hidden xl:hidden 2xl:flex  right-0 w-[380px] pr-4 fixed flex-col py-6 bg-transparent  overflow-y-scroll"
          style={{
            background: "#252946",
          }}
        >
          <div className="bg-white h-full shadow-xl rounded-md flex flex-col">
            <div className="px-4 sm:px-6 ">
              <div className="flex items-start justify-between">
                <div className="ml-3 h-7 flex items-center">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => cart.setIsCartOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-6 relative flex-1 px-4 sm:px-6">
              {/* Replace with your content */}
              <div className="absolute inset-0 px-4 sm:px-6">
                <div
                  className="h-full border-0 gap-y-2 flex flex-col border-gray-200"
                  aria-hidden="true"
                >
                  {cart.cart.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <div className="flex-1 min-w-0 pr-4">
                          <span className="focus:outline-none">
                            <p className="text-sm font-medium text-gray-900">
                              {item.stokadi}
                            </p>
                            <div className="flex flex-row justify-between">
                              <p className="text-sm text-gray-500 truncate">
                                Adet: {item.adet}
                              </p>
                              <p className="text-sm text-gray-500 truncate">
                                Fiyat: {numberToMoney(item.fiyat)}{" "}
                                {item.cinsi || "₺"}
                              </p>
                            </div>
                          </span>
                        </div>
                        <MdOutlineClose
                          className="absolute right-2 top-2 cursor-pointer"
                          onClick={() => cart.removeItemFromCart(i)}
                        />
                      </div>
                    );
                  })}
                  <div className="mt-auto text-sm flex-col flex mb-4">
                    <span className="w-full  text-secondary font-bold py-2 flex items-center justify-center rounded-md mb-2">
                      {cart.cart.length > 0 ? (
                        <div className="flex flex-col">
                          {Object.entries(getTotalPrice()).map(
                            ([key, value]) => {
                              return (
                                <span>
                                  Toplam Tutar {key} : {numberToMoney(value)}
                                </span>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        0
                      )}
                    </span>
                    <span
                      onClick={() => sendProducts()}
                      className="w-full cursor-pointer bg-green-400 text-white font-bold py-2 flex items-center justify-center rounded-md"
                    >
                      Siparişi Onayla
                    </span>
                  </div>
                </div>
              </div>
              {/* /End replace */}
            </div>
          </div>
        </div>
      </>
    );
  else return <></>;
});

export default ShopCart;

import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdOutlineClose } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaListUl } from "react-icons/fa";
import {
  AiOutlineFolder,
  AiOutlineFileDone,
  AiOutlineShoppingCart,
  AiFillDashboard,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import CrystalLogoSmall from "../images/crslogo.png";
import {
  useRouteMatch,
  NavLink,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import useStore from "../hooks/useStores";
import { observer } from "mobx-react-lite";
import { BiLogOutCircle } from "react-icons/bi";
import VomsisIcon from "../images/vomsis.svg";

//PAGES
import Products from "./Products";
import Orders from "./Orders";
import Extract from "./Extract";
import Vomsis from "./Vomsis/Vomsis";
import ShopCart from "../components/ShopCart";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Switch } from "react-router-dom";
import PaymentEnd from "../components/PaymentEnd";
import Home from "./Home";
import { RiRefund2Line } from "react-icons/ri";
import OldPayments from "./OldPayments";
import ContactUs from "./ContactUs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard = observer(() => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { auth, cart } = useStore();
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const Image = () => <img src={VomsisIcon} />;

  const navigation = [
    {
      name: "Anasayfa",
      href: url + "/homepage",
      icon: AiFillDashboard,
      current: true,
      component: Home,
      visible: auth.user.type === "admin" ? false : true,
    },
    {
      name: "Ürünler",
      href: url + "/products",
      icon: FaListUl,
      current: true,
      component: Products,
      visible: auth.user.type === "admin" ? false : true,
    },
    {
      name: "Bekleyen Siparişler",
      href: url + "/waiting-orders",
      icon: AiOutlineFolder,
      current: false,
      component: Orders,
      visible: auth.user.type === "admin" ? false : true,
    },
    {
      name: "Ekstre",
      href: url + "/extract",
      icon: AiOutlineFileDone,
      current: false,
      component: Extract,
      visible: auth.user.type === "admin" ? false : true,
    },

    {
      name: "ÖDEME YAP",
      href: url + "/vomsis",
      icon: Image,
      current: false,
      component: Vomsis,
      visible: false,
    },
    {
      name: "ÖDEMELER",
      href: url + "/refund",
      icon: RiRefund2Line,
      current: false,
      component: OldPayments,
      visible: auth.user.type === "admin" ? false : true,
    },
    {
      name: "Bize Ulaşın",
      href: url + "/contact-us",
      icon: AiOutlineQuestionCircle,
      current: false,
      component: ContactUs,
      visible: true,
    },
  ];

  return (
    <>
      <div className="bg-gray-800">
        <ToastContainer />
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <MdOutlineClose
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 max-h-[90vh] h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-14 w-auto"
                      src={CrystalLogoSmall}
                      alt="Workflow"
                    />
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map(
                      (item) =>
                        item.visible && (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              " hover:bg-gray-200 hover:text-gray-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-800"
                            )}
                            activeClassName=" bg-gray-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md shadow-lg text-gray-50"
                          >
                            <NavLink
                              to={item.href}
                              className={
                                "p-2 bg-gray-800 text-white mr-3 rounded-md"
                              }
                              activeClassName={
                                "p-2  mr-3 rounded-md bg-gray-800 text-white shadow-md"
                              }
                              activeStyle={{}}
                            >
                              <item.icon
                                className={classNames(
                                  "flex-shrink-0 h-6 w-6 max-h-6 max-w-6 "
                                )}
                                aria-hidden="true"
                              />
                            </NavLink>
                            {item.name}
                          </NavLink>
                        )
                    )}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex flex-col items-start bg-gray-700 p-4">
                  <a className="flex-shrink-0 pb-2 group block">
                    <div className="flex items-center">
                      <div>
                        <div className="h-9 w-9 rounded-full bg-white items-center justify-center flex text-2xl font-bold pb-1">
                          {auth?.user?.company?.charAt(0)}
                        </div>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">
                          {auth?.user?.company}
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="flex-shrink-0 flex">
                    <span
                      onClick={() => {
                        auth.signOut();
                        history.push("/login");
                      }}
                      className="flex-shrink-0 w-full group block cursor-pointer"
                    >
                      <div className="flex items-center">
                        <div>
                          <div className="h-9 w-9 rounded-full bg-white flex items-center justify-center font-bold text-2xl">
                            <BiLogOutCircle />
                          </div>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-white font-semibold">
                            Çıkış Yap
                          </p>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className="hidden md:flex md:w-64 p-2 md:flex-col md:fixed md:inset-y-0"
          style={{ background: "#252946" }}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div
            className="flex-1 flex flex-col min-h-0 bg-white shadow-md rounded"
            style={{ margin: ".5rem", borderRadius: ".5rem" }}
          >
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="h-14 w-auto"
                  src={CrystalLogoSmall}
                  alt="Workflow"
                />
              </div>
              <hr
                style={{
                  height: "0.25rem",
                  margin: "1rem 0px",
                  opacity: 0.3,
                  background:
                    "linear-gradient(130deg, #252946, #009db2 , #252946)",
                }}
              />
              <nav className="mt-3 flex-1 px-2 space-y-1 transition-all">
                {navigation.map(
                  (item) =>
                    item.visible && (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          " hover:bg-gray-200 hover:text-gray-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-800"
                        )}
                        activeClassName=" bg-gray-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md shadow-lg text-gray-50"
                      >
                        <NavLink
                          to={item.href}
                          className={
                            "p-2 bg-gray-800 text-white mr-3 rounded-md"
                          }
                          activeClassName={
                            "p-2  mr-3 rounded-md bg-gray-800 text-white shadow-md"
                          }
                          activeStyle={{}}
                        >
                          <item.icon
                            className={classNames(
                              "flex-shrink-0 h-6 w-6 max-h-6 max-w-6 "
                            )}
                            aria-hidden="true"
                          />
                        </NavLink>
                        {item.name}
                      </NavLink>
                    )
                )}
              </nav>
            </div>
            <div className="flex-shrink-0 flex  p-4">
              <span
                onClick={() => {
                  auth.signOut();
                  history.push("/login");
                }}
                className="flex-shrink-0 w-full group block cursor-pointer"
              >
                <div className="flex items-center">
                  <div>
                    <div className="h-9 w-9 rounded-full bg-white flex items-center justify-center font-bold text-2xl">
                      <BiLogOutCircle />
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-white font-semibold">
                      Çıkış Yap
                    </p>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <GiHamburgerMenu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 flex bg-gray-800 flex-row  ">
            <div className="py-6 flex-1 h-screen">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 flex flex-row">
                <h1 className="text-2xl  font-semibold text-white">
                  {navigation.length > 0 &&
                    navigation?.find(
                      (route) => route?.href === location?.pathname
                    )?.name}
                </h1>
                <h1
                  className="absolute xs:hidden md:flex z-10 flex-row right-4 top-4 shadow-xl text-md bg-white px-3 py-2 rounded-md font-semibold text-gray-900"
                  // style={{
                  //   display: window.innerWidth < 400 ? "none" : "flex",
                  // }}
                >
                  {auth?.user?.company}
                  <div className="flex-shrink-0 flex ml-12">
                    <span
                      onClick={() => {
                        auth.signOut();
                        history.push("/login");
                      }}
                      className="flex-shrink-0 w-full group block cursor-pointer"
                    >
                      <div className="flex items-center">
                        <div>
                          <div className="rounded-full bg-white flex items-center justify-center font-bold text-2xl">
                            <BiLogOutCircle color={"#F27B7C"} />
                          </div>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-500 font-semibold">
                            Çıkış Yap
                          </p>
                        </div>
                      </div>
                    </span>
                  </div>
                </h1>
              </div>
              <div
                className="max-w-7xl w-full mx-auto px-4 sm:px-6 md:px-8"
                style={{ background: "#252946", maxWidth: "100vw" }}
              >
                {/* Replace with your content */}
                <div className="py-4">
                  <div className=" rounded-lg ">
                    <Switch>
                      {navigation.map((nav, i) => {
                        if (nav.visible)
                          return (
                            <Route
                              key={i}
                              path={nav.href}
                              component={nav.component}
                            />
                          );
                      })}
                      <Redirect from={""} to={navigation[0].href} />
                    </Switch>
                  </div>
                </div>

                {/* /End replace */}
              </div>
            </div>
            <div
              className={
                location.pathname.includes("/products")
                  ? "w-[380px] ml-auto"
                  : ""
              }
            />
            <ShopCart />
          </main>
        </div>
      </div>

      {location.pathname.includes("/product") && (
        <button
          onClick={() => cart.setIsCartOpen(true)}
          className="fixed bottom-5 right-5 md:flex lg:flex xl:flex 2xl:hidden xs:flex rounded-full text-2xl bg-green-400 text-white p-3"
        >
          {cart.cart.length > 0 && (
            <span className="absolute -right-1 -top-1 bg-gray-600 rounded-full text-sm w-6 h-6 flex items-center justify-center">
              {cart.cart.length}
            </span>
          )}

          <AiOutlineShoppingCart />
        </button>
      )}
    </>
  );
});

export default Dashboard;

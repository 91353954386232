import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import instance from "../../lib/client";
import { Dialog, Transition } from "@headlessui/react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import Select from "react-select";

import PaymentModal from "../../components/PaymentModal/PaymentModal";
import useStore from "../../hooks/useStores";
import Installments from "./Installments";
import InstallmentsModal from "./InstallmentsModal";
import DiscountModal from "./DiscountModal";
import { observer } from "mobx-react-lite";
import ReactSelect from "react-select";

const days = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

const years = [
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
  { value: 2025, label: "2025" },
  { value: 2026, label: "2026" },
  { value: 2027, label: "2027" },
  { value: 2028, label: "2028" },
  { value: 2029, label: "2029" },
  { value: 2030, label: "2030" },
  { value: 2031, label: "2031" },
  { value: 2032, label: "2032" },
  { value: 2033, label: "2033" },
  { value: 2034, label: "2034" },
  { value: 2035, label: "2035" },
  { value: 2036, label: "2036" },
  { value: 2037, label: "2037" },
  { value: 2038, label: "2038" },
  { value: 2039, label: "2039" },
  { value: 2040, label: "2040" },
  { value: 2041, label: "2041" },
];

const Vomsis = observer(() => {
  const [htmlContent, setHtmlContent] = useState("");
  const [focused, setFocused] = useState("");
  const [installments, setInstallments] = useState([]);
  const [openAllInstallments, setOpenAllInstallments] = useState(false);
  const [OpenAllDiscount, setOpenAllDiscount] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(0);
  const { auth, loading } = useStore();

  useEffect(() => {
    if (auth.user.type === "admin") {
      getClients();
    }
    auth?.user?.list === 2 ? setOpenAllDiscount(true) : setOpenAllDiscount(false); 
  }, []);


    
 
 

  const html = `
  <!DOCTYPE html>
  <html>
    <body>
        <script>
        ${htmlContent}
        </script>
    </body>
    
  </html>
  `;
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",

    defaultValues: {
      creditCardHolderName: "",
      creditCardPan: "",
      creditCardExpiryMonth: "01",
      creditCardExpiryYear: "2022",
      creditCardCvc: undefined,
      installment: 1,
      extraInstallment: 0,
      amount: 0,
      paymentNote: "",
      userName: "",
      userEmail: "",
      userPhone: "",
      userTaxOffice: "",
      userTaxNo: "",
      userAddress: "",
      clientIp: "",
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const installment = useWatch({ control: control, name: "installment" });



  //console.log(installment);

  const getInstallments = async (value) => {
    const { data } = await instance
      .get(`/payment/installment/${String(value).substring(0, 6)}`)
      .catch((err) => console.log(err));

    if (data?.length > 0) {
      setInstallments(data);
      setRefresh(!refresh);
    } else {
      setRefresh(!refresh);
      setInstallments([]);
    }
  };

  const getClients = async () => {
    const { data } = await instance.get("/customer");
    setClients(
      data.map((d) => {
        return {
          label: d.unvan,
          value: d.hesap,
        };
      })
    );
  };

  const onSubmit = async (data) => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setValue("clientIp", res.data.IPv4);

    const newData =
      auth.user.type === "admin"
        ? {
            ...data,
            customCari: selectedClient.label,
            customHesap: selectedClient.value,
          }
        : data;
    instance
      .post("/payment", newData)
      .then((response) => {
        instance.post("/payment/start-3d", {
          url: response.data.gateway,
          content: response.data.inputs,
        });
        setHtmlContent(response.data.htmlContent);
        loading.setPaymentModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CustomInput = ({ label, objectName, tf, ...rest }) => {
    return (
      <div className="transition-all">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
          {errors[objectName] && (
            <span className="text-xs text-red-700">(Bu alan gerekli)</span>
          )}
        </label>
        <div className="mt-1">
          {objectName ? (
            <input
              className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                errors[objectName] && "border-red-600 ring-red-700"
              }`}
              {...register(objectName, { required: tf })}
              {...rest}
            />
          ) : (
            <input
              className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                errors[objectName] && "border-red-600 ring-red-700"
              }`}
              {...rest}
            />
          )}
        </div>
      </div>
    );
  };

  const CreditCard = () => {
    return (
      <Cards
        placeholders={{
          name: "KART SAHİBİ",
        }}
        preview={false}
        number={useWatch({ control: control, name: "creditCardPan" })}
        name={useWatch({ control: control, name: "creditCardHolderName" })}
        expiry={
          useWatch({ control: control, name: "creditCardExpiryMonth" }) +
          "/" +
          useWatch({ control: control, name: "creditCardExpiryYear" })
        }
        cvc={useWatch({ control: control, name: "creditCardCvc" })}
        focused={focused}
      />
    );
  };

  return (
    <div className="flex w-full">
      <PaymentModal
        html={html}
        open={loading.paymentModal}
        setOpen={loading.setPaymentModal}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex flex-row gap-x-4 justify-around w-full"}
      >
        <div
          style={{
            background: "white",

            width: "100%",
            borderRadius: ".3rem",
          }}
          className={"flex md:flex-row xs:flex-col-reverse"}
        >
          <div style={{ padding: "1rem", margin: "auto" }}>
            {auth.user.type === "admin" && (
              <>
                <label
                  htmlFor="select"
                  className="block text-sm font-medium text-gray-700"
                >
                  Müşteri
                </label>
                <div className="mb-2 max-w-[368px]">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    value={selectedClient}
                    placeholder={"Seçiniz"}
                    onChange={(v) => {
                      setSelectedClient(v);
                    }}
                    isSearchable={true}
                    name="color"
                    options={clients}
                  />
                </div>
              </>
            )}

            <Controller
              name="creditCardHolderName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  value={field.value}
                  label={"Kart Sahibi"}
                  onFocus={() => setFocused("creditCardHolderName")}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="creditCardPan"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  value={field.value}
                  label={"Kart Numarasi"}
                  onBlur={(e) => {
                    if (String(field?.value)?.split("").length >= 5) {
                      getInstallments(field.value);
                    }
                  }}
                  onFocus={() => setFocused("number")}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  errors={errors}
                />
              )}
            />

            <div className="flex flex-row gap-x-2">
              <Controller
                name="creditCardExpiryMonth"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="min-w-[130px]">
                    <label className="block text-sm font-medium text-gray-700">
                      {"Son Kullanım Ayı"}
                      {errors["creditCardExpiryMonth"] && (
                        <span className="text-xs text-red-700">
                          (Bu alan gerekli)
                        </span>
                      )}
                    </label>
                    <ReactSelect
                      className="mt-1"
                      placeholder="MM"
                      onFocus={() => setFocused("expiry")}
                      options={days}
                      value={
                        days.find(
                          (d) => String(d.label) === String(field.value)
                        ) || null
                      }
                      onChange={(v) => field.onChange(String(v.label))}
                    />
                  </div>
                )}
              />
              <Controller
                name="creditCardExpiryYear"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="min-w-[160px]">
                    <label className="block text-sm font-medium text-gray-700">
                      {"Son Kullanım Yılı"}
                      {errors["creditCardExpiryYear"] && (
                        <span className="text-xs text-red-700">
                          (Bu alan gerekli)
                        </span>
                      )}
                    </label>

                    <ReactSelect
                      className="mt-1"
                      placeholder="YYYY"
                      onFocus={() => setFocused("expiry")}
                      options={years}
                      value={
                        years.find(
                          (d) => String(d.value) === String(field.value)
                        ) || null
                      }
                      onChange={(v) => {
                        field.onChange(String(v.value));
                      }}
                    />
                  </div>
                )}
              />
            </div>

            <Controller
              name="creditCardCvc"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  value={field.value}
                  label={"CVC"}
                  onFocus={() => setFocused("cvc")}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  errors={errors}
                />
              )}
            />

            <Controller
              name="amount"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  value={field.value}
                  label={"Tutar"}
                  onChange={(value) => {
                    // setCurrentPrice(value);
                    field.onChange(value);
                  }}
                  errors={errors}
                />
              )}
            />

            {/* <CustomInput label={"Tutar"} objectName={"amount"} /> */}
            <CustomInput label={"E-mail"} objectName={"userEmail"} tf={false} />
            <CustomInput label={"Telefon"} objectName={"userPhone"} tf={true} />
            <CustomInput label={"Not"} objectName={"paymentNote"} tf={false} />

            <button
              style={{ marginTop: "5px" }}
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Ödeme Yap
            </button>
          </div>

          <div
            style={{ padding: "1rem", marginLeft: "auto", marginRight: "auto" }}
            className={"xs:flex xs:items-center flex-col max-w-[100%]"}
          >
            <CreditCard />

            {auth?.user?.list === 2 ? <button
              onClick={() => {
                setOpenAllDiscount(true);
              }}
              type="button"
              class="inline-flex w-full justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-secondary hover:bg-indigo-700 focus:outline-none focus:ring-2 my-1 focus:ring-offset-2 focus:ring-indigo-500"
            >
              İskonto Seçeneklerini Gör
            </button> : ""

            }
            <button
              onClick={() => {
                setOpenAllInstallments(true);
              }}
              type="button"
              class="inline-flex w-full justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-secondary hover:bg-indigo-700 focus:outline-none focus:ring-2 my-1 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Tüm Taksit Seçeneklerini Gör
            </button>

            {installments.length > 0 && (
              <div className="xs:max-w-[91vw] overflow-x-auto md:max-w-auto">
                <Installments
                  setValue={setValue}
                  installment={installment}
                  installments={installments}
                  control={control}
                  list={auth?.user?.list || 0}
                />
              </div>
            )}
          </div>
        </div>
      </form>
      <InstallmentsModal
        open={openAllInstallments}
        setOpen={setOpenAllInstallments}
        control={control}
      />
      <DiscountModal
        open={OpenAllDiscount}
        setOpen={setOpenAllDiscount}
        control={control}
      />
    </div>
  );
});

export default Vomsis;

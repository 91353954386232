import { action, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import instance from "../lib/client";

class CartStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: ["cart"],
      storage: localStorage,
    });
  }

  isCartOpen = false;
  cart = [];

  deleteCart = () => {
    this.cart = [];
  };

  addToFavorite = async (product) => {
    return await instance.post("/product/add-favorite", product);
  };

  remoteFromFavorite = async (product) => {
    return await instance.post("/product/remove-favorite", product);
  };

  removeItemFromCart = (index) => {
    this.cart.splice(index, 1);
  };

  addItemToCart = (item) => {
    this.cart.push(item);
  };

  setIsCartOpen = (status) => {
    this.isCartOpen = status;
  };
}

const cartStore = new CartStore();

export default cartStore;

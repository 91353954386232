import { action, makeAutoObservable } from "mobx";

class LoadingStore {
  constructor() {
    makeAutoObservable(this);
  }

  paymentModal = false;
  overlayLoading = false;

  setPaymentModal = (value) => {
    this.paymentModal = value;
  };

  closePaymentModalAndGoDashboard = () => {
    this.paymentModal = false;
    window.location.href = "/dashboard/home";
  };

  setOverlayLoading = (status) => {
    this.overlayLoading = status;
  };
}

const loadingStore = new LoadingStore();

export default loadingStore;

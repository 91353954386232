import React, { useState, useEffect, useRef } from "react";
import numberToMoney from "../utils/numberToMoney";
import instance from "../lib/client";
import Loading from "react-loading";
import moment from "moment";
import ReactModal from "react-modal";
import { IoMdDownload } from "react-icons/io";
import OrderDetailModal from "../components/OrderDetailModal";
import ExcelLoading from "../components/ExcelLoading";
import downloadExcelFile from "../utils/downloadExcelFile";
import useStore from "../hooks/useStores";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";

const Orders = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [tempOrders, setTempOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { auth } = useStore();
  const [search, setSearch] = useState("");
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleData = async () => {
    setLoading(true);
    const { data } = await instance.get("orders");

    setOrders(data);
    console.log(orders);
    setTempOrders(data);
    setLoading(false);
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (moment(startDate).isValid() && moment(endDate).isValid()) {
      const newData = tempOrders.filter((ext) => {
        if (
          moment(endDate)
            .endOf("day")
            .isAfter(moment(ext.tarih, "YYYY/MM/DD")) &&
          moment(startDate)
            .startOf("day")
            .isBefore(moment(ext.tarih, "YYYY/MM/DD"))
        )
          return true;
      });

      setOrders(newData);
    } else if (moment(endDate).isValid()) {
      const newData = tempOrders.filter((ext) => {
        if (
          moment(endDate).endOf("day").isAfter(moment(ext.tarih, "YYYY/MM/DD"))
        )
          return true;
      });

      setOrders(newData);
    } else if (moment(startDate).isValid()) {
      const newData = tempOrders.filter((ext) => {
        if (
          moment(startDate)
            .startOf("day")
            .isBefore(moment(ext.tarih, "YYYY/MM/DD"))
        )
          return true;
      });

      setOrders(newData);
    } else {
      setOrders(tempOrders);
    }
  }, [startDate, endDate, setEndDate, setStartDate]);

  const getTotalPrice = (array) => {
    let totalPrice = 0;

    if (array) {
      array?.map((d) => {
        totalPrice += d.siptut;
      });
    } else return totalPrice;

    return totalPrice;
  };

  const onSearch = (text) => {
    if (text) {
      const newData = tempOrders.filter((order) => {
        if (order?.mlzadi?.indexOf(text?.toUpperCase()) !== -1) return order;
      });
      setOrders(newData);
    } else {
      setOrders(tempOrders);
    }
  };

  const exportExcelFile = async () => {
    setIsDownloading(true);
    console.log(auth);
    await downloadExcelFile(
      `/excel/waiting-orders`,
      "Bekleyen Siparisler",
      auth.accessToken,
      setIsDownloading
    );
  };

  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="flex flex-row gap-4 pb-2">
            <input
              type={"text"}
              className={
                "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer sm:ml-0"
              }
              onChange={(e) => {
                setSearch(e.target.value);
                onSearch(e.target.value);
              }}
              placeholder="Ara..."
              value={search}
            />
            <div className="cursor-pointer">
              {isDownloading ? (
                <ExcelLoading />
              ) : (
                <IoMdDownload
                  size={24}
                  style={{ marginTop: 5, marginRight: 2 }}
                  color={"#ffffff"}
                  onClick={exportExcelFile}
                />
              )}
            </div>
            <div className="mr-auto cursor-pointer">
              <AiFillPrinter
                size={24}
                style={{ marginTop: 5, marginRight: 2 }}
                color={"#ffffff"}
                onClick={handlePrint}
              />
            </div>
            <input
              type={"text"}
              className={
                "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer md:ml-auto sm:ml-0"
              }
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Başlangıç Tarihi"
              onFocus={(e) => (e.target.type = "date")}
            />
            <input
              type={"text"}
              className={
                "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer"
              }
              placeholder="Bitiş Tarihi"
              onChange={(e) => setEndDate(e.target.value)}
              onFocus={(e) => (e.target.type = "date")}
            />
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              ref={componentRef}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Sipariş No
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Sipariş Tarihi
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Ürün
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Termin Tarihi
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Açıklama
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Sipariş Adeti
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Adet Fiyatı
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Sipariş Tutarı
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="flex items-center justify-center w-full ">
                    <Loading color={"#fff"} />
                  </div>
                ) : (
                  orders &&
                  Object.values(orders)?.map((order, i) => (
                    <>
                      <tr key={i} className={"bg-white"}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.sipno}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {moment(order.tarih, "YYYY/MM/DD").format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {/*<div
                            style={{
                              position: "relative",
                              display: "flex",
                              verticalAlign: "middle",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                background:
                                  "linear-gradient(310deg, #ea0606, #ff667c)",
                                color: "white",
                                borderRadius: ".25rem",
                                textAlign: "center",
                                verticalAlign: "middle",
                                padding: "0.575em 0.775em 0.4em",
                              }}
                            >
                              {order.durum}
                            </div>
                            </div>*/}
                          {order.mlzadi}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.termin.length <= 0
                            ? ""
                            : moment(order.termin, "YYYY/MM/DD").format(
                                "DD-MM-YYYY"
                              )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.acik}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.sipbak}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {numberToMoney(order.sipfyt) + " "}₺
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {numberToMoney(order.siptut) + " "}₺
                        </td>
                      </tr>
                      {/* {order.data.map((d) => (
                        <tr style={{ backgroundColor: "aliceblue" }}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {"Ürün : " + d.mlzadi}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {"Sipariş Adeti : " + d.sipbak}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {"Adet Fiyatı : " + numberToMoney(d.sipfyt)} ₺
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {numberToMoney(d.siptut)} ₺
                          </td>
                        </tr>
                      ))} */}
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;

import React, { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { Dialog } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import { useRouteMatch, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStore from "../hooks/useStores";

const query = new URLSearchParams(window.location.search);

const PaymentEnd = observer(({ type, amount, refNo }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { loading, auth } = useStore();

  useEffect(() => {}, []);

  return (
    <div className="flex items-center justify-center">
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            {type.toLowerCase() === "approved" ? (
              <BsFillCheckCircleFill
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            ) : (
              <AiFillCloseCircle
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {type.toLowerCase() === "approved"
                ? "Ödeme Başarılı!"
                : "Ödeme Başarısız!"}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500"></p>
            </div>
            {type.toLowerCase() === "approved" && (
              <h3
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {"Miktar " + amount}
              </h3>
            )}
            {type.toLowerCase() === "approved" && (
              <h3
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {"Referans Numarasi " + refNo}
              </h3>
            )}
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <span
            onClick={() => {
              if (auth.user.type === "admin") {
                loading.setPaymentModal(false);
                window.location.reload();
              } else {
                loading.closePaymentModalAndGoDashboard();
              }
            }}
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm cursor-pointer"
          >
            {auth.user.type === "admin"
              ? "Odeme sayfasini yenile"
              : "Anasayfa'ya Dön"}
          </span>
        </div>
      </div>
    </div>
  );
});

export default PaymentEnd;

import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

import "./index.css";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const DiscountModal = ({ open, setOpen, control }) => {

  return (
    <ReactModal
      style={customStyles}
      isOpen={open}
      ariaHideApp={false}
      onRequestClose={() => setOpen(false)}
    >
      <div className="flex flex-row justify-between">
        <h1 className="text-xl text-center font-extrabold mb-2">TAKSİT SAYISINA GÖRE İSKONTO ORANLARI</h1>
        <AiOutlineClose
          className="text-2xl cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="flex flex-row flex-wrap" style={{ gap: ".3rem" }}>
            <table className="xs:min-w-[100%]">
              <thead className="font-extrabold">
                <th>TAKSİT SAYISI</th>
                <th className="text-red-500 text-lg">İSKONTO ORANI</th>
              </thead>
              <tbody className="text-center">              
               <tr>
                 <td>TEK ÇEKİM</td>
                 <td className="text-red-500 text-lg font-bold">15%</td>
                </tr>
                <tr>
                 <td>2 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">15%</td>
                </tr>
                <tr>
                 <td>3 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">11,5%</td>
                </tr>
                <tr>
                 <td>4 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">10%</td>
                </tr>
                <tr>
                 <td>5 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">8,5%</td>
                </tr>
                <tr>
                 <td>6 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">7%</td>
                </tr>
                <tr>
                 <td>7 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">5,5%</td>
                </tr>
                <tr>
                 <td>8 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">4%</td>
                </tr>
                <tr>
                 <td>9 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">2,5%</td>
                </tr>
                <tr>
                 <td>10 TAKSİT</td>
                 <td className="text-red-500 text-lg font-bold">0%</td>
                </tr>
              </tbody>
            </table>
      </div>
    </ReactModal>
  );
};

export default DiscountModal;

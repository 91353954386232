import React from "react";
import { useWatch } from "react-hook-form";
import notif from "../../utils/notify";

const Installments = ({
  setValue,
  list,
  installments,
  installment,
  control,
}) => {
  const value = useWatch({ control: control, name: "amount" });

  console.log(installment);

  const ncont = (ext) => {
    if (ext == null || "") {
      return 0;
    } else {
      return ext;
    }
  };
  return (
    <table className="divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
          >
            SEÇ
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
          >
            TAKSİT
          </th>
          {/* parseInt(list) == 2 ? (
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                >
                  İSKONTO
                </th>
              ) : (
                ""
              ) */}
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
          >
            TAKSİT TUTARI
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
          >
            TOPLAM
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {installments.map((item, i) => {
          if (parseInt(list) == 1 || parseInt(list) == 6) {
            if (i <= 1)
              return (
                <tr key={i} className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center h-5">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        checked={installment === parseInt(item.amount)}
                        name="comments"
                        onChange={() => {
                          setValue("installment", parseInt(item.amount));
                          setValue(
                            "extraInstallment",
                            parseInt(ncont(item.extraAmount))
                          );
                          if (item.extraAmount) {
                            notif.info(
                              'Lütfen çekim yaptıktan sonra kartınızın artı taksit kampayası dahilinde oldup olmadığını kontrol ediniz!!!'
                            );
                          }
                        }}
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {item.extraAmount != null || ""
                      ? item.title + " (+" + item.extraAmount + "Taksit )"
                      : item.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    {parseFloat(
                      value /
                        (parseInt(item.amount) +
                          parseInt(ncont(item.extraAmount)))
                    ).toFixed(2)}
                    ₺
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    {value}₺
                  </td>
                </tr>
              );
          } else if (parseInt(list) == 7) {
            if (i <= 3)
              return (
                <tr key={i} className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center h-5">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        checked={installment === parseInt(item.amount)}
                        name="comments"
                        onChange={() => {
                          setValue("installment", parseInt(item.amount));
                          setValue(
                            "extraInstallment",
                            parseInt(ncont(item.extraAmount))
                          );
                          if (item.extraAmount) {
                            notif.info(
                              'Lütfen çekim yaptıktan sonra kartınızın artı taksit kampayası dahilinde oldup olmadığını kontrol ediniz!!!'
                            );
                          }
                        }}
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {item.extraAmount != null || ""
                      ? item.title + " (+" + item.extraAmount + "Taksit )"
                      : item.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    {parseFloat(
                      value /
                        (parseInt(item.amount) +
                          parseInt(ncont(item.extraAmount)))
                    ).toFixed(2)}
                    ₺
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                    {value}₺
                  </td>
                </tr>
              );
          } else {
            return (
              <tr key={i} className="bg-white">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex items-center h-5">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      checked={installment === parseInt(item.amount)}
                      name="comments"
                      onChange={() => {
                        setValue("installment", parseInt(item.amount));

                        setValue(
                          "extraInstallment",
                          parseInt(ncont(item.extraAmount))
                        );
                        if (item.extraAmount) {
                          notif.info(
                            'Lütfen çekim yaptıktan sonra kartınızın artı taksit kampayası dahilinde oldup olmadığını kontrol ediniz!!!'
                          );
                        }
                      }}
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.extraAmount != null || ""
                    ? item.title + " (+" + item.extraAmount + "Taksit )"
                    : item.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {parseFloat(
                    value /
                      (parseInt(item.amount) +
                        parseInt(ncont(item.extraAmount)))
                  ).toFixed(2)}
                  ₺
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {value}₺
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default Installments;

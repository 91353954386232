import React, { useEffect, useState } from "react";
import IframeComm from "react-iframe-comm";
import { useHistory } from "react-router-dom";
import useStore from "../../hooks/useStores";
import PaymentEnd from "../PaymentEnd";

const IFrame = ({ children, ...props }) => {
  const { loading } = useStore();
  const [isPaymentEnd, setIsPaymentEnd] = useState(false);
  const [paymentData, setPaymentData] = useState({
    type: "",
    amount: "",
    refNo: "",
  });

  const onMessageReceive = (e) => {
    if (e.data.type) {
      setPaymentData({
        type: e.data.type,
        amount: e.data.amount,
        refNo: e.data.refNo,
      });
      setIsPaymentEnd(true);
    }
  };

  useEffect(() => {
    setIsPaymentEnd(false);
    // window.addEventListener("messageEvent", function (event) {
    //   if (event.type) {
    //     setPaymentData({
    //       type: event.type,
    //       amount: event.amount,
    //       refNo: event.refNo,
    //     });
    //     setIsPaymentEnd(true);
    //   }
    // });
  }, []);

  return (
    <>
      {!isPaymentEnd ? (
        <IframeComm
          attributes={{
            srcDoc: children,
            height: 500,
            width: 500,
            target: "_parent",
            sandbox: `allow-forms allow-modals	allow-orientation-lock	allow-pointer-lock	allow-popups	allow-popups-to-escape-sandbox	allow-presentation	allow-same-origin	allow-scripts	allow-top-navigation`,
          }}
          targetOrigin="*"
          handleReceiveMessage={onMessageReceive}
        />
      ) : (
        <PaymentEnd
          type={paymentData.type}
          amount={paymentData.amount}
          refNo={paymentData.refNo}
        />
      )}
    </>
  );
};

export default IFrame;

import FlatList from "flatlist-react";
import React from "react";
import Loading from "react-loading";
import numberToMoney from "../utils/numberToMoney";

const ExtractDetails = ({ data }) => {
  return (
    <div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Ürün Adı
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Miktar
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Fiyat
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Tutar
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length <= 0 ? (
            <div className="flex items-center justify-center w-full ">
              <Loading color={"#fff"} />
            </div>
          ) : (
            <FlatList
              list={data}
              renderOnScroll
              renderItem={(item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.mlzadi}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.hmik}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {numberToMoney(item.hfyt)} ₺
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {numberToMoney(item.htutar)} ₺
                  </td>
                </tr>
              )}
              renderWhenEmpty={() => (
                <div className="flex items-center justify-center w-full "></div>
              )}
              //sortBy={["firstName", {key: "lastName", descending: true}]}
              //groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ExtractDetails;

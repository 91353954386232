import React, { useState } from "react";
import ReactSelect from "react-select";
import useStore from "../hooks/useStores";
import instance from "../lib/client";
import notif from "../utils/notify";

const options = [
  {
    label: "Öneri",
    value: "oneri",
  },
  {
    label: "İstek",
    value: "istek",
  },
  {
    label: "Şikayet",
    value: "sikayet",
  },
  {
    label: "Bilgi Degişikliği Talebi",
    value: "bilgi",
  },
];

function tcontrol(x, y) {
  if (x === "") {
    return y;
  } else if (y === "") {
    return x;
  } else {
    return x + " - " + y;
  }
}

const ContactUs = () => {
  const { auth } = useStore();
  const [email, setEmail] = useState({
    about: "",
    baslik: "",
    icerik: "",
  });

  const sendMail = async () => {
    const { data } = await instance.post("/mail/info", {
      subject: email.about?.label,
      title: email.baslik,
      desc: email.icerik,
    });

    if (data.success) {
      notif.success(data.message);
      setEmail({
        about: "",
        baslik: "",
        icerik: "",
      });
    } else {
      notif.error(data.message);
    }
  };

  return (
    <div className="flex md:flex-row xs:flex-col h-full w-full gap-4">
      <div className="md:w-6/12 xs:w-12/12 flex bg-white rounded-md p-4 flex-col gap-4 ">
        <h3 className="font-semibold text-2xl ">FİRMA BİLGİLERİ</h3>
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className="text-sm min-w-[100px] ">Ünvan :</label>
          <input
            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            value={auth.user.company}
            disabled
          />
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className="text-sm min-w-[100px] ">Tel :</label>
          <input
            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            value={tcontrol(auth.user.phone , auth.user.phone2)}
            disabled
          />
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className="text-sm min-w-[100px] ">Mail :</label>
          <input
            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            value={auth.user.email}
            disabled
          />
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className="text-sm min-w-[100px] ">Adres :</label>
          <input
            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            value={auth.user.adres}
            disabled
          />
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className="text-sm min-w-[100px] ">Vergi No :</label>
          <input
            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            value={auth.user.vNo}
            disabled
          />
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <label className="text-sm min-w-[100px] ">Vergi Dairesi :</label>
          <input
            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            value={auth.user.vName}
            disabled
          />
        </div>
      </div>
      <div className="md:w-6/12 xs:w-12/12 min-h-[90vh] flex bg-white rounded-md p-4 flex-col gap-4 items-center">
        <ReactSelect
          className="mt-1 min-w-[300px]"
          placeholder="Şeciniz..."
          options={options}
          value={email.about}
          onChange={(v) => {
            setEmail({
              ...email,
              about: v,
            });
          }}
        />
        <input
          placeholder="Başlık"
          className={`appearance-none block w-[300px] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          value={email.baslik}
          onChange={(e) =>
            setEmail({
              ...email,
              baslik: e.target.value,
            })
          }
        />
        <input
          placeholder="Açıklama"
          className={`appearance-none block w-[300px] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          value={email.icerik}
          onChange={(e) =>
            setEmail({
              ...email,
              icerik: e.target.value,
            })
          }
        />
        <span
          onClick={() => sendMail()}
          className="w-[300px] cursor-pointer bg-green-400 text-white font-bold py-2 flex items-center justify-center rounded-md"
        >
          Gönder
        </span>
      </div>
    </div>
  );
};

export default ContactUs;

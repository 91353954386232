import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactSrcDocIframe from "react-srcdoc-iframe";
import ReactModal from "react-modal";
import IFrame from "./IFrame";
import { useHistory } from "react-router-dom";

const customStyles = {
  content: {
    width: 500,
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "auto",
    height: 540,
  },
};
const PaymentModal = ({ setOpen, open, html }) => {
  return (
    <ReactModal
      style={customStyles}
      isOpen={open}
      onRequestClose={() => {}}
      as={Fragment}
    >
      <div
        className="flex items-center justify-center"
        style={{
          minHeight: 500,
          height: 500,
        }}
      >
        <IFrame>{html}</IFrame>
        {/* <ReactSrcDocIframe
          onLoad={(data) => {
            console.log(data);
          }}
          srcDoc={html}
          width="500"
          height="500"
        /> */}
      </div>
    </ReactModal>
  );
};

export default PaymentModal;

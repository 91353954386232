import React, { useState, useEffect, useRef } from "react";
import numberToMoney from "../utils/numberToMoney";
import instance from "../lib/client";
import Loading from "react-loading";
import Tabs from "../components/Tabs";
import FlatList from "flatlist-react";
import moment from "moment";
import ExtractDetails from "../components/ExtractDetails";
import useStore from "../hooks/useStores";
import downloadExcelFile from "../utils/downloadExcelFile";
import ExcelLoading from "../components/ExcelLoading";
import { IoMdDownload } from "react-icons/io";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";

const Extract = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [extracts, setExtracts] = useState([]);
  const [extarctsTemp, setExtarctsTemp] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentTab, setCurrentTab] = useState("Ekstreler");
  const [startDate, setStartDate] = useState(moment().startOf("year"));
  const [extractDetails, setExtractDetails] = useState({});
  const [endDate, setEndDate] = useState(moment());
  const { auth } = useStore();
  const { loading } = useStore();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleData = async () => {
    setIsLoading(true);
    let data = [];
    const response = await instance
      .get(
        "extract?start=" +
          moment(startDate).format("DD-MM-YYYY") +
          "&end=" +
          moment(endDate).format("DD-MM-YYYY")
      )
      .catch((err) => {
        data = [];
      });

    if (response?.data) {
      data = response.data;
    }

    const newData = {};

    data.forEach((item) => {
      //if (item.wfkn === 0) {
      //} else {
      newData[item.wfkn] = newData[item.wfkn] || {
        wtarih: item.wtarih,
        wisad: item.wisad,
        wacik: item.wacik,
        wbakiye: item.wbakiye,
        wborc: item.wborc,
        walacak: item.walacak,
        wfkn: item.wfkn,
        wvade: item.wvade,
        wbelge: item.wbelge,
        data: [],
      };

      newData[item.wfkn]?.data?.push(item);
      // }
    });

    console.log(newData);
    setExtracts(newData);
    //setExtarctsTemp(data);
    setIsLoading(false);
  };

  const exportExcelFile = async () => {
    setIsDownloading(true);
    console.log(auth);

    await downloadExcelFile(
      "/excel/extract?start=" +
        moment(startDate).format("DD-MM-YYYY") +
        "&end=" +
        moment(endDate).format("DD-MM-YYYY"),
      "Ekstreler",
      auth.accessToken,
      setIsDownloading
    );
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    handleData();
  }, [startDate, endDate, setEndDate, setStartDate]);

  const getExtractDetails = async (id) => {
    loading.setOverlayLoading(true);
    const { data } = await instance.get("extract/" + id);

    setCurrentTab(tabs[1].name);
    setExtractDetails(data);
    loading.setOverlayLoading(false);
  };

  function wcontrol(x, y) {
    if (
      x === "Satış Faturası" ||
      x === "Alış Faturası" ||
      x === "İade Girişi"
    ) {
      return x;
    } else if (y === "D e v i r.") {
      return y;
    } else {
      return x + " - " + y;
    }
  }

  const tabs = [
    {
      name: "Ekstreler",
    },
    {
      name: "Ekstre Detaylari",
    },
  ];

  return (
    <div className="flex flex-col ">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="sm:px-4 lg:mx-4 flex md:flex-row xs:flex-col gap-3">
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            onClick={(t) => setCurrentTab(t)}
          />

          <div className="cursor-pointer">
            {isDownloading ? (
              <ExcelLoading />
            ) : (
              <IoMdDownload
                size={24}
                style={{ marginTop: 5, marginRight: 2 }}
                color={"#ffffff"}
                onClick={exportExcelFile}
              />
            )}
          </div>
          <div className="mr-auto cursor-pointer">
            <AiFillPrinter
              size={24}
              style={{ marginTop: 5, marginRight: 2 }}
              color={"#ffffff"}
              onClick={handlePrint}
            />
          </div>
          <input
            type={"text"}
            className={
              "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer md:ml-auto sm:ml-0"
            }
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Başlangıç Tarihi"
            onFocus={(e) => (e.target.type = "date")}
          />
          <input
            type={"text"}
            className={
              "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer"
            }
            placeholder="Bitiş Tarihi"
            onChange={(e) => setEndDate(e.target.value)}
            onFocus={(e) => (e.target.type = "date")}
          />
        </div>
        <div className="py-2 align-middle inline-block max-w-[50%] overflow-x-auto min-w-full sm:px-6 lg:px-8 ">
          {currentTab === "Ekstreler" ? (
            <table ref={componentRef} className=" divide-y divide-gray-200" style={{ "width": "100%"  }}>
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tarih
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    İşlem Adı
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Belge No
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Vade
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Borç
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Alacak
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bakiye
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {extracts.length <= 0 ? (
                  <div className="flex items-center justify-center w-full ">
                    <Loading color={"#fff"} />
                  </div>
                ) : (
                  <FlatList
                    list={currentTab === "Ekstreler" ? extracts : []}
                    renderOnScroll
                    renderItem={(item, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-xxs font-medium text-gray-900">
                          {item.wtarih.length <= 0
                            ? ""
                            : moment(item.wtarih, "YYYY/MM/DD").format(
                                "DD-MM-YYYY"
                              )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xxs text-gray-500">
                          {wcontrol(item.wisad, item.wacik)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xxs text-gray-500">
                          {item.wbelge}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xxs text-gray-500">
                          {item.wvade}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xxs text-gray-500">
                          {numberToMoney(item.wborc)} ₺
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xxs text-gray-500">
                          {numberToMoney(item.walacak)} ₺
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-xxs text-gray-500">
                          {numberToMoney(item.wbakiye)} ₺
                        </td>

                        <td
                          onClick={() =>
                            item.wisad === "Satış Faturası" ||
                            item.wisad === "Alış Faturası" ||
                            item.wisad === "İade Girişi"
                              ? getExtractDetails(item.wfkn)
                              : ""
                          }
                          className="px-6 py-4 whitespace-nowrap text-right text-xxs font-medium"
                        >
                          <span className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                            Ekstre Detaylari
                          </span>
                        </td>
                      </tr>
                    )}
                    renderWhenEmpty={() => (
                      <div className="flex items-center justify-center w-full ">
                        {isLoading && <Loading color={"#1F2937"} />}
                      </div>
                    )}
                    //sortBy={["firstName", {key: "lastName", descending: true}]}
                    //groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                  />
                )}
              </tbody>
            </table>
          ) : (
            <ExtractDetails data={extractDetails} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Extract;

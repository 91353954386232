import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import ReactModal from "react-modal";
import instance from "../../lib/client";
import { AiOutlineClose } from "react-icons/ai";
import numberToMoney from "../../utils/numberToMoney";

import "./index.css";

const customStyles = {
  content: {
    width: window.innerWidth < 1000 ? window.innerWidth : 1000,
    marginLeft: "auto",
    marginRight: "auto",
  },
};

const InstallmentsModal = ({ open, setOpen, control }) => {
  const [installments, setInstallments] = useState([]);
  const amount = useWatch({ control: control, name: "amount" });

  const handleInstallments = async () => {
    const { data } = await instance.get("/payment/installment");

    setInstallments(data.pos_banks);
  };

  console.log(installments);

  useEffect(() => {
    handleInstallments();
  }, []);

  return (
    <ReactModal
      style={customStyles}
      isOpen={open}
      ariaHideApp={false}
      onRequestClose={() => setOpen(false)}
    >
      <div className="flex flex-row justify-between">
        <h1 className="text-xl text-center mb-2">Taksit Seçenekleri</h1>
        <AiOutlineClose
          className="text-2xl cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="flex flex-row flex-wrap" style={{ gap: ".3rem" }}>
        {installments.map((installment, index) => {
          return (
            <table className="md:min-w-[32.2%] xs:min-w-[100%]" key={index}>
              <tr>
                <th colSpan={3}><img src={require('../../images/' + installment.family + '.jpg').default} /></th>
              </tr>
              <tr>
                <th>Taksit</th>
                <th>Taksit Tutarı</th>
                <th>Toplam Tutar</th>
              </tr>

              {installment.installment.map((d, i) => {
                if (i <= 11)
                  return (
                    <tr key={i}>
                      <td>
                        {d.installment}
                        <span
                          style={{
                            fontSize: 14,
                            marginLeft: "3px",

                          }}
                        >
                          {d.extra_installment && d.extra_installment !== 0
                            ? `(+${d.extra_installment})`
                            : ""}
                        </span>
                      </td>
                      <td>
                        {d.status === "false"
                          ? "----"
                          : numberToMoney(amount / (parseInt(d.installment) + parseInt(d.extra_installment)))}
                      </td>
                      <td>
                        {d.status === "false" ? "----" : numberToMoney(amount)}
                      </td>
                    </tr>
                  );
                else return null;
              })}
            </table>
          );
        })}
      </div>
    </ReactModal>
  );
};

export default InstallmentsModal;

import React, { useState, useEffect } from "react";
import useStore from "../hooks/useStores";
import instance from "../lib/client";
import FlatList from "flatlist-react";
import numberToMoney from "../utils/numberToMoney";
import Loading from "react-loading";
import Tabs from "../components/Tabs";
import notif from "../utils/notify";
import Select from "react-select";

const Products = () => {
  const { auth, cart } = useStore();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [search, setSearch] = useState("");

  const [productGroup, setProductsGroup] = useState([]);
  const [selectedPGroup, setSelectedPGroup] = useState([]);

  const [pUnderGroup, setPUnderGroup] = useState([]);
  const [selectedpUnderGroup, setSelectedpUnderGroup] = useState([]);

  const [pUnderrGroup, setPUnderrGroup] = useState([]);
  const [selectedpUnderrGroup, setSelectedpUnderrGroup] = useState([]);

  const [currentTab, setCurrentTab] = useState("Ürünler");

  const getProducts = async () => {
    setLoading(true);
    const { data } = await instance
      .get(
        "product?fgrp=" +
          selectedPGroup.value +
          "&fagrp=" +
          selectedpUnderGroup.value +
          "&fatgrp=" +
          selectedpUnderrGroup.value
      )
      .catch((err) => {
        data = [];
      });
    setProducts(data);
    setTempProducts(data);
    setLoading(false);
  };

  const getProductsGroup = async () => {
    const { data } = await instance.get("product/groups");
    setProductsGroup(
      data.map((d) => {
        return {
          label: d.grpadi,
          value: d.grpkod,
        };
      })
    );
  };

  const getProductsUnderGroup = async (altid) => {
    const { data } = await instance
      .get("product/groups/" + altid)
      .catch((err) => {
        data = [];
      });
    setPUnderGroup(
      data.map((d) => {
        return {
          label: d.grpadi,
          value: d.grpkod,
        };
      })
    );
  };

  const getProductsUnderrGroup = async (altid) => {
    const { data } = await instance
      .get("product/groups/alt/" + altid)
      .catch((err) => {
        data = [];
      });
    setPUnderrGroup(
      data.map((d) => {
        return {
          label: d.grpadi,
          value: d.grpkod,
        };
      })
    );
  };

  const getFavoriteProducts = async () => {
    setLoading(true);
    const { data } = await instance.get("product/favorite-products");

    setFavoriteProducts(data);
    setLoading(false);
  };

  useEffect(() => {
    getProductsGroup();
    getFavoriteProducts();
    setLoading(false);
  }, []);

  useEffect(() => {
    getProducts();
  }, [
    selectedPGroup,
    selectedpUnderGroup,
    selectedpUnderrGroup,
    setSelectedPGroup,
    setSelectedpUnderGroup,
    setSelectedpUnderrGroup,
  ]);

  const addToFavorite = async (product) => {
    const { data } = await cart.addToFavorite(product);
    getFavoriteProducts();
    if (data.status) {
      notif.success(data.message);
      setCurrentTab(tabs[1].name);
    } else {
      notif.error(data.message);
    }
  };

  const removeFromFavorite = async (product) => {
    const { data } = await cart.remoteFromFavorite(product);
    getFavoriteProducts();
    notif.success(data.message);

    setCurrentTab(tabs[1].name);
  };

  const RenderProduct = ({ product, index, isFavorite }) => {
    const [count, setCount] = useState(1);

    return (
      <tr className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
        <td className="px-6 max-w-[200px] whitespace-pre-wrap py-2 text-sm font-medium text-gray-900">
          {product.stokadi}
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          {product.grupadi}
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          <div
            style={{
              position: "relative",
              verticalAlign: "middle",
            }}
          >
            {(() => {
              if (auth?.user?.vurun === "yes" && product.bakiye > 0) {
                return (
                  <div
                    style={{
                      position: "relative",
                      background: "linear-gradient(310deg, #3dc034, #3dc034)",
                      color: "white",
                      borderRadius: ".25rem",
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0.575em 0.775em 0.4em",
                    }}
                  >
                    {product.bakiye}
                  </div>
                );
              } else if (auth?.user?.vurun === "no" && product.bakiye > 0) {
                return (
                  <div
                    style={{
                      position: "relative",
                      background: "linear-gradient(310deg, #3dc034, #3dc034)",
                      color: "white",
                      borderRadius: ".25rem",
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0.575em 0.775em 0.4em",
                    }}
                  >
                    VAR
                  </div>
                );
              } else if (product.bakiye <= 0) {
                return (
                  <div
                    style={{
                      position: "relative",
                      background: "linear-gradient(310deg, #ea0606, #ff667c)",
                      color: "white",
                      borderRadius: ".25rem",
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0.575em 0.775em 0.4em",
                    }}
                  >
                    YOK
                  </div>
                );
              }
            })()}
          </div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
          {numberToMoney(product.fiyat)} {product?.cinsi || "₺"}
        </td>
        <td className="px-6 py-2 flex flex-row whitespace-nowrap text-right text-sm font-medium">
          <input
            placeholder="Adet"
            style={{ width: "53%", minWidth: 100 }}
            value={count}
            type={"number"}
            onChange={(e) => setCount(e.target.value)}
            className={`appearance-none  px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          />
          &nbsp;
          <div className="flex flex-col">
            <span
              onClick={() => {
                if (count > 0) {
                  cart.addItemToCart({ ...product, adet: count });
                  setCount(0);
                }
              }}
              className="text-indigo-600 p-0.5 hover:text-indigo-900 cursor-pointer"
            >
              Siparişe Ekle
            </span>
            <span
              onClick={() => {
                if (isFavorite) {
                  removeFromFavorite(product);
                } else {
                  addToFavorite(product);
                }
              }}
              className="text-gray text-[#17ad37] p-0.5 hover:text-indigo-900 cursor-pointer"
            >
              {isFavorite ? "Favorilerden Çıkar" : "Favorilere Ekle"}
            </span>
          </div>
        </td>
      </tr>
    );
  };

  const onSearch = (text) => {
    if (text) {
      const newData = tempProducts.filter((product) => {
        if (product?.stokadi?.toLowerCase().indexOf(text?.toLowerCase()) !== -1)
          return product;
        else if (
          product?.grupadi?.toLowerCase().indexOf(text?.toLowerCase()) !== -1
        )
          return product;
        else if (
          product?.stkno?.toLowerCase().indexOf(text?.toLowerCase()) !== -1
        )
          return product;
      });
      console.log(newData);
      setProducts(newData);
    } else {
      setProducts(tempProducts);
    }
  };

  const tabs = [
    {
      name: "Ürünler",
    },
    {
      name: "Favori Ürünler",
    },
  ];

  return (
    <>
      <div className="mb-2 flex flex-row items-center justify-between">
        <div style={{ width: "33%" }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            value={selectedPGroup}
            placeholder={"Ürün Grubu Seçiniz..."}
            onChange={(v) => {
              if (v === undefined || v === null || v === "") {
                setSelectedPGroup([]);
              } else {
                getProductsUnderGroup(v.value);
                setSelectedPGroup(v);
                console.log(v);
              }
            }}
            isSearchable={true}
            name="color"
            options={productGroup}
          />
        </div>
        {/* <div style={{ width: "33%" }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            value={selectedpUnderGroup}
            placeholder={"Alt Grup Seçiniz..."}
            onChange={(v) => {
              if(v === undefined || v === null || v === ""){
                setSelectedpUnderGroup([]);
              }else{
                getProductsUnderrGroup(v.value);
                setSelectedpUnderGroup(v);
                console.log(v);
            }
            }}
            isSearchable={true}
            name="color"
            options={pUnderGroup}
          />
        </div>
        <div style={{ width: "33%" }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            value={selectedpUnderrGroup}
            placeholder={"Alt Grup Seçiniz..."}
            onChange={(v) => {
              if(v === undefined || v === null || v === ""){
                setSelectedpUnderrGroup([]);
              }else{
                setSelectedpUnderrGroup(v);
                console.log(v);
            }
            }}
            isSearchable={true}
            name="color"
            options={pUnderrGroup}
          />
          </div> */}
      </div>
      <div className="mb-2 flex flex-row items-center justify-between">
        <Tabs
          currentTab={currentTab}
          tabs={tabs}
          onClick={(t) => {
            console.log(t);
            setCurrentTab(t);
          }}
        />
        <input
          type="text"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onSearch(e.target.value);
          }}
          required
          placeholder="Ara..."
          className="appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div
        className="flex flex-col"
        style={{
          maxWidth: window.innerWidth,
          overflowX: "auto",
        }}
      >
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
              style={{
                maxHeight: "86vh",
                overflowY: "auto",
              }}
            >
              <table className="min-w-full  overflow-x-auto divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ürün
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ürün tipi
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Stok
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fiyat
                    </th>
                    <th
                      scope="col"
                      className="relative px-6 py-3"
                      span="1"
                      width="25%"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {loading ? (
                    <div>
                      <Loading color={"#fff"} />
                    </div>
                  ) : currentTab === tabs[1].name ? (
                    favoriteProducts.map((product, index) => {
                      return (
                        <RenderProduct
                          key={index}
                          product={product}
                          index={index}
                          isFavorite={true}
                        />
                      );
                    })
                  ) : (
                    products.map((product, index) => {
                      return (
                        <RenderProduct
                          key={index}
                          product={product}
                          index={index}
                          isFavorite={false}
                        />
                      );
                    })
                  )}
                  {/* <FlatList
                    list={currentTab === "Ürünler" ? products : []}
                    renderOnScroll
                    filterBy={(item) =>
                      search
                        ? item.stokadi.includes(search) ||
                          item.stkno.includes(search)
                        : true
                    }
                    renderItem={RenderProduct}
                    renderWhenEmpty={() => (
                      <div className="flex items-center justify-center w-full ">
                        {loading && <Loading color={"#1F2937"} />}
                      </div>
                    )}
                    //sortBy={["firstName", {key: "lastName", descending: true}]}
                    //groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                  /> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

import React, { useEffect, useState } from "react";
import LineChart from "../components/Charts/LineChart";
import Stats from "../components/Stats";
import useStore from "../hooks/useStores";
import instance from "../lib/client";
import numberToMoney from "../utils/numberToMoney";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineUser,
  AiFillMail,
  AiFillCustomerService,
} from "react-icons/ai";

const Home = () => {
  const [fvPrd, setFvPrd] = useState([]);
  const { auth } = useStore();
  const [stats, setStats] = useState([]);

  const getFavoriProducts = async () => {
    const { data } = await instance.get("product/favorite-products");
    setFvPrd(data);
  };

  const getStats = async () => {
    const { data } = await instance.get("dashboard");
    setStats([
      {
        id: 1,
        name: "Güncel Bakiye",
        stat: auth.user?.bakiye?.trim() + " ₺",
        icon: AiOutlineUser,
        css: "bg-gradient-to-tr from-pink-500 via-red-500 to-yellow-500",
      },
      {
        id: 2,
        name: "Bekleyen Siparişler",
        stat: data.waitingOrders,
        icon: AiFillCustomerService,
        css: "bg-gradient-to-r from-red-200 to-red-600",
      },
      {
        id: 3,
        name: "Bekleyen Sipariş Tutarı",
        stat: numberToMoney(data.waitingOrdersPrice) + " ₺",
        icon: AiFillCustomerService,
        css: "bg-gradient-to-r from-purple-400 to-yellow-400",
      },
    ]);
  };

  useEffect(() => {
    getFavoriProducts();
    getStats();
  }, []);

  return (
    <div className="max-w-screen ">
      <Stats stats={stats} />
      <div className="flex flex-col mt-4">
        <h2 className="text-2xl font-extrabold tracking-tight text-white mb-4">
          Favori Ürünler
        </h2>

        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle  inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden bg-red-400 border-gray-200 sm:rounded-lg flex md:flex-row xs:flex-col gap-x-4">
              <div className="flex w-full ">
                <table className="divide-y divide-gray-200 w-full">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ÜRÜN
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ÜRÜN TİPİ
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        STOK
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        STOK
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fvPrd?.map((prd, i) => (
                      <tr
                        key={i}
                        className={i % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {prd.stokadi}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {prd.grupadi}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              verticalAlign: "middle",
                            }}
                          >
                            {prd.bakiye > 0 ? (
                              <div
                                style={{
                                  position: "relative",
                                  background:
                                    "linear-gradient(310deg, #17ad37, #98ec2d)",
                                  color: "white",
                                  borderRadius: ".25rem",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  padding: "0.575em 0.775em 0.4em",
                                }}
                              >
                                VAR
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: "relative",
                                  background:
                                    "linear-gradient(310deg, #ea0606, #ff667c)",
                                  color: "white",
                                  borderRadius: ".25rem",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  padding: "0.575em 0.775em 0.4em",
                                }}
                              >
                                YOK
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {numberToMoney(prd.fiyat)}₺
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStore from "../../hooks/useStores";
import Loading from "react-loading";
import CrystalLogoSmall from "../../images/crystal-logo-small.png";
import authService from "../../services/authService";

const PublicRoute = observer(({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const { auth } = useStore();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (auth.accessToken) {
        authService.setAuthorizationToken(auth.accessToken);
      }
      setLoading(false);
    }, 500);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <div className="w-screen h-screen flex-col flex items-center justify-center">
            <img className="h-20" src={CrystalLogoSmall} />
            <Loading color={"#249EB2"} type={"cylon"} width={120} />
          </div>
        ) : auth?.isLogged === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/dashboard`} />
        )
      }
    />
  );
});

export default PublicRoute;

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import Loading from "react-loading";
import ReactModal from "react-modal";
import instance from "../../lib/client";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";

const PaymentDetailModal = ({ isOpen, setIsOpen, details, setDetails }) => {
  const [unvan, setUnvan] = useState("");
  const [loading, setLoading] = useState(false);
  const componentRef = useRef();
  function closeModal() {
    setIsOpen(false);

    setTimeout(() => {
      setDetails({});
    }, 400);
  }

  const customStyles = {
    content: {
      width: 500,
      marginLeft: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      marginRight: "auto",
      height: 800,
    },
  };

  const Title = ({ text }) => {
    return <span className="col-span-2 text-xl">{text}</span>;
  };

  const Divider = () => {
    return <div className="w-full border-2 h-[1px] mt-1 mb-1 rounded-lg" />;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const Row = ({ label, value }) => {
    return (
      <div className="flex flex-row">
        <span className="col-span-1">{label}</span>
        <span className="col-span-1 ml-auto">{value}</span>
      </div>
    );
  };

  return (
    <ReactModal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      as={Fragment}
    >
      <div className="w-full h-full flex relative flex-col">
        <div
          className="absolute top-0 right-0 z-10 cursor-pointer"
          onClick={() => handlePrint()}
        >
          <AiFillPrinter className="w-6 h-6" />
        </div>
        <div
          ref={componentRef}
          className="grid gap-2 relative grid-cols-2 w-full px-4"
        >
          <Title text={"Müşteri Bilgileri"} />
          <Divider />
          <Row
            label={"Müsteri Ad Soyad/Unvan :"}
            value={
              loading ? (
                <Loading color="black" type="spin" width={20} height={20} />
              ) : (
                details.unvan || (
                  <span className="text-red-500">Belirtilmemis</span>
                )
              )
            }
          />
          <Row
            label={"Telefon Numarasi (GSM) :"}
            value={
              details.userPhone || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          <Row
            label={"Müsteri E-posta Adresi :"}
            value={
              details.userEmail || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />

          <Divider />
          <Title text={"Fatura Bilgileri"} />
          <Divider />

          <Row
            label={"Unvan :"}
            value={
              loading ? (
                <Loading color="black" type="spin" width={20} height={20} />
              ) : (
                details.unvan || (
                  <span className="text-red-500">Belirtilmemis</span>
                )
              )
            }
          />
          <Row
            label={"Yetkili :"}
            value={
              details?.username || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          <Row
            label={"Telefon Numarasi (GSM) :"}
            value={
              details.userPhone || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          <Row
            label={"E-posta Adresi :"}
            value={
              details.userEmail || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          <Row
            label={"Vergi No/TC Kimlik :"}
            value={
              details.identitiy || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          <Row
            label={"Vergi Dairesi :"}
            value={
              details.taxOffice || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          {/* <Row
            label={"Adres :"}
            value={
              details.address || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          /> */}
          <Row
            label={"Bilgilendirme E-Posta Adresi :"}
            value={
              details.userEmail || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          <Divider />
          <Title text={"Banka Bilgileri"} />
          <Divider />
          <Row label={"POS :"} value={details?.bankName} />
          <Row label={"Referans Kodu :"} value={details?.rfrNo} />
          <Row
            label={"Aciklama :"}
            value={
              details?.description || (
                <span className="text-red-500">Belirtilmemis</span>
              )
            }
          />
          <Row label={"Islem Tarihi :"} value={details?.transactionDate} />
          <Row
            label={"Tutar :"}
            value={details?.amount + " " + details.currenyCode}
          />
          <Row label={"Taksit :"} value={details?.installemnt} />
        </div>
      </div>
    </ReactModal>
  );
};

export default PaymentDetailModal;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ onClick, tabs, currentTab }) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-secondary focus:border-secondary border-gray-300 rounded-md"
          defaultValue={currentTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <span
              key={tab.name}
              onClick={() => onClick(tab.name)}
              className={classNames(
                currentTab === tab.name
                  ? "bg-white text-secondary shadow-xl"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
              )}
            >
              {tab.name}
            </span>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;

import React from "react";
import authStore from "../stores/authStore";
import loadingStore from "../stores/loadingStore";
import cartStore from "../stores/cartStore";

const storeContext = React.createContext({
  auth: authStore,
  loading: loadingStore,
  cart: cartStore,
});

export default storeContext;

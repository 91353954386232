import React, { useEffect, useRef, useState } from "react";
import Loading from "react-loading";
import instance from "../lib/client";
import notif from "../utils/notify";
import numberToMoney from "../utils/numberToMoney";
import { IoMdDownload } from "react-icons/io";
import downloadExcelFile from "../utils/downloadExcelFile";
import useStore from "../hooks/useStores";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { AiFillPrinter } from "react-icons/ai";
import ExcelLoading from "../components/ExcelLoading";
import PaymentDetailModal from "../components/PaymentDetailModal/PaymentDetailModal";

const OldPayments = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [oldPayments, setOldPayments] = useState([]);
  const [tempPayments, setTempPayments] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState({});
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { auth } = useStore();

  const onSearch = (text) => {
    if (text) {
      const newData = tempPayments.filter((order) => {
        if (
          String(order?.creditCardHolderName.toUpperCase())?.indexOf(
            text?.toUpperCase()
          ) !== -1
        ) {
          console.log("works");
          return order;
        } else if (order?.posName?.indexOf(text?.toUpperCase()) !== -1) {
          return order;
        } else if (
          order?.transactionDate?.indexOf(text?.toUpperCase()) !== -1
        ) {
          return order;
        }
      });
      console.log(newData);
      setOldPayments(newData);
    } else {
      setOldPayments(tempPayments);
    }
  };

  const exportExcelFile = async () => {
    setIsDownloading(true);
    await downloadExcelFile(
      `/excel/payments`,
      "Ödemeler",
      auth.accessToken,
      setIsDownloading
    );
  };

  const getOldPayments = async () => {
    setLoading(true);
    const { data } = await instance.get("payment/old-payments");

    data.sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1));

    setOldPayments(data);
    setTempPayments(data);
    setLoading(false);
  };

  useEffect(() => {
    getOldPayments();
  }, []);

  // const refundIt = async (type, amount, refNo) => {
  //   instance
  //     .post(`payment/return/${type}`, {
  //       referanceNo: refNo,
  //       amount: amount,
  //     })
  //     .then((res) => {
  //       if (res?.data?.success) {
  //         notif.success(res?.data?.message);
  //       } else {
  //         notif.error(res?.data?.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const RenderPayment = ({ payment, index }) => {
  //   const [amount, setAmount] = useState(payment.amount);

  //   return (
  //     <tr className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
  //       <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //         {payment.rfrno}
  //       </td>
  //       <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
  //         {payment.hesap}
  //       </td>
  //       <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
  //         {payment.creditCardHolderName}
  //       </td>
  //       <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
  //         {payment.description}
  //       </td>
  //       <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
  //         {payment.posName}
  //       </td>
  //       <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
  //         {payment.transactionDate}
  //       </td>
  //       <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
  //         {numberToMoney(payment.amount) + " TL"}
  //       </td>
  //       <td className="px-6 py-2 flex flex-row whitespace-nowrap text-right text-sm font-medium">
  //         <input
  //           placeholder="Iade Edilecek Tutar"
  //           style={{ width: "53%", minWidth: 100 }}
  //           value={amount}
  //           type={"number"}
  //           onChange={(e) => setAmount(e.target.value)}
  //           className={`appearance-none  px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
  //         />

  //         <div className="flex flex-row justify-between w-full">
  //           <span
  //             onClick={() => refundIt("refund", amount, payment.rfrno)}
  //             className="text-indigo-600 p-4 hover:text-indigo-900 cursor-pointer"
  //           >
  //             Iade Et
  //           </span>
  //           <span
  //             onClick={() => refundIt("cancel", amount, payment.rfrno)}
  //             className="text-gray text-[#17ad37] p-4 hover:text-indigo-900 cursor-pointer"
  //           >
  //             İptal Et
  //           </span>
  //         </div>
  //       </td>
  //     </tr>
  //   );
  // };

  return (
    <div className="flex flex-col overflow-x-hidden w-full ">
      <div className="-my-2 overflow-x-hidden sm:-mx-6  lg:-mx-8">
        <div className="py-2 align-middle overflow-x-hidden  inline-block min-w-full sm:px-6 lg:px-8">
          <div className="flex flex-row gap-4 pb-2">
            <input
              type={"text"}
              className={
                "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer sm:ml-0"
              }
              onChange={(e) => {
                setSearch(e.target.value);
                onSearch(e.target.value);
              }}
              placeholder="Ara..."
              value={search}
            />
            <div className="cursor-pointer">
              {isDownloading ? (
                <ExcelLoading />
              ) : (
                <IoMdDownload
                  size={24}
                  style={{ marginTop: 5, marginRight: 2 }}
                  color={"#ffffff"}
                  onClick={exportExcelFile}
                />
              )}
            </div>
            <div className="mr-auto cursor-pointer">
              <AiFillPrinter
                size={24}
                style={{ marginTop: 5, marginRight: 2 }}
                color={"#ffffff"}
                onClick={handlePrint}
              />
            </div>
            {/* <input
              type={"text"}
              className={
                "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer md:ml-auto sm:ml-0"
              }
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Başlangıç Tarihi"
              onFocus={(e) => (e.target.type = "date")}
            />
            <input
              type={"text"}
              className={
                "rounded-md p-1 placeholder:text-sm text-sm outline-none px-2 min-w-[160px] max-w-[160px] xs:max-w-full cursor-pointer"
              }
              placeholder="Bitiş Tarihi"
              onChange={(e) => setEndDate(e.target.value)}
              onFocus={(e) => (e.target.type = "date")}
            /> */}
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              ref={componentRef}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Kart Sahibi
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Not
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Banka
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Tarih
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Taksit
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                  >
                    Fiyat
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="flex items-center justify-center w-full ">
                    <Loading color={"#fff"} />
                  </div>
                ) : (
                  oldPayments &&
                  oldPayments?.map((order, i) => (
                    <>
                      <tr key={i} className={"bg-white "}>
                        {/*<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.rfrno}
                        </td>
                          <div
                        style={{
                          position: "relative",
                          display: "flex",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            background:
                              "linear-gradient(310deg, #ea0606, #ff667c)",
                            color: "white",
                            borderRadius: ".25rem",
                            textAlign: "center",
                            verticalAlign: "middle",
                            padding: "0.575em 0.775em 0.4em",
                          }}
                        >
                          {order.durum}
                        </div>
                        </div>*/}
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {order.creditCardHolderName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.description}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.bankName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.transactionDate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {order.installemnt}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap flex flex-row items-center text-sm text-gray-900">
                          <span>{numberToMoney(order.amount) + " TL"}</span>

                          <div
                            className="px-2 bg-gray-500 rounded-md text-white ml-auto cursor-pointer py-1"
                            onClick={() => {
                              setDetails(order);
                              setIsOpen(true);
                            }}
                          >
                            Detay
                          </div>
                        </td>
                      </tr>
                      {/* {order.data.map((d) => (
                    <tr style={{ backgroundColor: "aliceblue" }}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {"Ürün : " + d.mlzadi}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {"Sipariş Adeti : " + d.sipbak}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {"Adet Fiyatı : " + numberToMoney(d.sipfyt)} ₺
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {numberToMoney(d.siptut)} ₺
                      </td>
                    </tr>
                  ))} */}
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PaymentDetailModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        details={details}
        setDetails={setDetails}
      />
    </div>
  );

  // return (
  //   <div
  //     className="flex flex-col"
  //     style={{
  //       maxWidth: window.innerWidth,
  //       overflowX: "auto",
  //     }}
  //   >
  //     <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
  //       <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
  //         <div
  //           className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
  //           style={{
  //             maxHeight: "86vh",
  //             overflowY: "auto",
  //           }}
  //         >
  //           <table className="min-w-full  overflow-x-auto divide-y divide-gray-200">
  //             <thead className="bg-gray-50">
  //               <tr>
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 >
  //                   Referans No
  //                 </th>
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 >
  //                   Musteri Hesap
  //                 </th>
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 >
  //                   Alici Adi
  //                 </th>
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 >
  //                   Not
  //                 </th>
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 >
  //                   Banka
  //                 </th>{" "}
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 >
  //                   Tarih
  //                 </th>
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 >
  //                   Fiyat
  //                 </th>
  //                 <th
  //                   scope="col"
  //                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  //                 ></th>
  //               </tr>
  //             </thead>
  //             <tbody className="w-full">
  //               {loading ? (
  //                 <div>
  //                   <Loading color={"#1F2937"} />
  //                 </div>
  //               ) : (
  //                 oldPayments.map((product, index) => {
  //                   return (
  //                     <RenderPayment
  //                       key={index}
  //                       payment={product}
  //                       index={index}
  //                     />
  //                   );
  //                 })
  //               )}

  //               {/* <FlatList
  //             list={currentTab === "Ürünler" ? products : []}
  //             renderOnScroll
  //             filterBy={(item) =>
  //               search
  //                 ? item.stokadi.includes(search) ||
  //                   item.stkno.includes(search)
  //                 : true
  //             }
  //             renderItem={RenderProduct}
  //             renderWhenEmpty={() => (
  //               <div className="flex items-center justify-center w-full ">
  //                 {loading && <Loading color={"#1F2937"} />}
  //               </div>
  //             )}
  //             //sortBy={["firstName", {key: "lastName", descending: true}]}
  //             //groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
  //           /> */}
  //             </tbody>
  //           </table>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default OldPayments;
